<template>
  <div class="main">
    <van-tabs v-model:active="active" class="common-tabs" @change="handleChangeTab">
      <van-tab name="1" title="时效型">
      </van-tab>
      <van-tab name="2" title="次数型">
      </van-tab>
    </van-tabs>
    <div class="table">
      <div class="thead">
        <div class="tr">
          <div class="td">套餐名称</div>
          <div class="td">{{ active == '1' ? '时效' : '次数' }}</div>
          <div class="td">售价</div>
          <div class="td">利润</div>
          <div class="td">状态</div>
          <div class="td">操作</div>
        </div>
      </div>
      <div class="tbody" style="max-height:calc(100vh - 76px);overflow:auto">
        <div class="tr" v-for="item in data" :key="item.id">
          <div class="td">{{ item.mc || '-' }}</div>
          <div class="td">
            <span v-if="item.type == '1'">{{ item.yxqx }}天</span>
            <span v-else>{{ item.xfcs }}次</span>
          </div>
          <div class="td">{{ item.shoujia || '-' }}</div>
          <div class="td">{{ item.lirun || '-' }}</div>
          <div class="td">
            <span v-if="item.status == '1'">上架中</span>
            <span style="color:red;" v-if="item.status == '0'">已下架</span>
          </div>
          <div class="td">
            <van-button size="mini" type="primary" @click="edit(item)">编辑</van-button>
          </div>
        </div>
        <div style="margin: 16px;margin-top:32px;">
          <van-button @click="toCreate" round block type="primary">
            + 添加
          </van-button>
        </div>
      </div>
    </div>

    <van-dialog v-model:show="show" @confirm="submit" :title="current ? '新建' : '编辑'" show-cancel-button>
      <van-cell-group inset v-if="current">
        <van-field v-model="current.mc" label="套餐名称" placeholder="请输入" />
        <van-field v-if="active == '1'" type="digit" v-model="current.yxqx" label="套餐时效" placeholder="请输入">
          <template #button>
            <span>天</span>
          </template>
        </van-field>
        <van-field v-else type="digit" v-model="current.xfcs" label="套餐次数" placeholder="请输入">
          <template #button>
            <span>次</span>
          </template>
        </van-field>
        <van-field type="digit" v-model="current.shoujia" label="套餐售价" placeholder="请输入">
          <template #button>
            <span>元</span>
          </template>
        </van-field>
        <van-field type="digit" v-model="current.lirun" label="利润" placeholder="请输入">
          <template #button>
            <span>元</span>
          </template>
        </van-field>
        <van-cell center title="状态">
          <template #right-icon>
            <van-switch v-model="editStatus" />
          </template>
        </van-cell>
      </van-cell-group>

    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import dayjs from 'dayjs';
import { showConfirmDialog, showNotify, showToast } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      active: '1',
      value: "",
      store: null,
      loading: false,
      data: [],
      current: null,
      show: false,
      editStatus: false,
      filter: {
        search: '',
      }
    };
  },
  async created() {
    this.getData();
  },
  watch: {
    $route(route) {
      this.filter.search = route.query.search || '';
      this.getData();
    }
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    handleChangeTab(e) {
      console.log(e);
      this.getData();
    },
    async submit() {
      this.loading = true;
      let { data: res } = await axios.get(this.current.id ? "/api/api/edit_md_taocan" : "/api/api/add_md_taocan", {
        params: {
          ...this.current,
          status: this.editStatus ? '1' : '0',
          type: this.current.type || this.active,
          sjhm: this.$route.query.sjhm
        }
      });
      this.loading = false;
      if (res?.code) {
        this.show = false;
        this.current = null;
        showToast(res.msg);
        this.getData(this.$route.query.search);
      }
      else {
        showToast(res.msg || '失败');
      }

    },
    toCreate() {
      this.current = {
        sjhm: '',
        mc: '',
        yxqx: '',
        xfcs: '',
        guige: '',
        shoujia: '',
        lirun: '',
        status: '0',
      };
      this.editStatus = false;
      this.show = true;
    },
    edit(item) {
      this.current = { ...item };
      this.editStatus = item.status == '1' ? true : false;
      this.show = true;
    },
    async getData() {
      let { data: res } = await axios.get("/api/api/get_md_taocan", {
        params: {
          name: this.filter.search,
          sjhm: this.$route.query.sjhm
        }
      });
      this.data = res.data.filter(f => f.type == this.active);
    },
    async getStore() {
      let { data: res } = await axios.get("/api/api/get_guanli_mendian", {
        params: {
          sjhm: this.$route.query.sjhm
        }
      });
      this.store = res.data?.[0] || null;
    },
  },
  components: {}
}
</script>
<style lang="less" scoped>
.main {
  flex: 1;
  overflow: auto;
  width: 100%;
}

.table {
  text-align: center;

  .thead {
    color: #fff;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 32px;
      align-items: center;
      justify-content: space-between;
      background: #3395a2;

      .overlayHead {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 100%;

        .btn {
          padding: 0 24px;
          height: 24px;
          display: flex;
          align-items: center;
        }
      }

      .td {
        &:first-child {
          flex: 1.5;
        }

        flex: 1;
      }
    }
  }

  .tbody {
    color: #333;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 44px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e6e7;

      .td {
        padding: 6px 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          flex: 1.5;
        }

        &.red {
          color: red;
        }
      }
    }
  }
}
</style>
